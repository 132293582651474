/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperationalHours,
    OperationalHoursFromJSON,
    OperationalHoursFromJSONTyped,
    OperationalHoursToJSON,
} from './OperationalHours';

/**
 * 
 * @export
 * @interface HubHoursOperationalHours
 */
export interface HubHoursOperationalHours {
    /**
     * 
     * @type {string}
     * @memberof HubHoursOperationalHours
     */
    begins: string | null;
    /**
     * 
     * @type {string}
     * @memberof HubHoursOperationalHours
     */
    ends: string | null;
}

export function HubHoursOperationalHoursFromJSON(json: any): HubHoursOperationalHours {
    return HubHoursOperationalHoursFromJSONTyped(json, false);
}

export function HubHoursOperationalHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): HubHoursOperationalHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'begins': json['begins'],
        'ends': json['ends'],
    };
}

export function HubHoursOperationalHoursToJSON(value?: HubHoursOperationalHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'begins': value.begins,
        'ends': value.ends,
    };
}

