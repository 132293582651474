import { Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { initialValues } from 'pages/Ship/Shipping/Add/helpers/initialValues';
import { shipmentValidationSchema } from 'pages/Ship/Shipping/Add/helpers/validation';
import { useShipment } from '../hooks/useShipment';
import AdditionalDetails from './AdditionalDetails/AdditionalDetails';
import { LabelFileOptions } from './LableFileOptions/LabelFileOptions';
import Package from './Package/Package';
import Recipient from './Recipient/Recipient';
import Shipper from './Shipper/Shipper';

const LabelForm = () => {
  const { defaultShipperAddress, actions, servicePurchaseData } = useShipment();
  const { handleSubmitLabelDetailsForm } = actions;
  const { serviceType, packageInfo, shipperPostalCode } = servicePurchaseData || {};
  // Since we don't want to pass isResidential in shipper form, we are destructuring it here to get rest of the values.
  const { isResidential: _, ...shipperInitialValues } = defaultShipperAddress || {
    ...initialValues.shipper,
    postalCode: shipperPostalCode ?? '',
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        serviceType,
        ...packageInfo,
        shipper: shipperInitialValues,
      }}
      validationSchema={shipmentValidationSchema}
      onSubmit={handleSubmitLabelDetailsForm}
      enableReinitialize
    >
      <Form>
        <Box>
          <Shipper />
          <Recipient />
          <Package />
          <AdditionalDetails />
          <LabelFileOptions />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center' }}>
          <Button sx={{ mt: 2, width: '100%' }} size="large" variant="contained" type="submit">
            Continue
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};

export default LabelForm;
