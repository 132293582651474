import { Box } from '@mui/system';
import { HubAddressT } from 'pages/Ship/Generic/helpers/types';
import HubList from 'pages/Ship/UserShippingAddress/components/EditHubAddress/components/Hubs/HubList';

interface SelectHubsProps {
  next: (hub: HubAddressT) => void;
  prev: () => void;
}

const SelectHubs = ({ prev, next }: SelectHubsProps) => {
  return (
    <Box sx={{ flexDirection: 'column', overflow: 'auto', display: 'flex', width: '100%' }}>
      {/* Promise.resolve is used to resolve the promise and call the next function */}
      <HubList prev={prev} selectHub={(hub) => Promise.resolve(next(hub))} />
    </Box>
  );
};

export default SelectHubs;
