import { Form as FormikForm } from 'formik';
import { initialShipDate } from 'pages/Ship/Shipping/Add/helpers/initialValues';
import { Actions } from './components/Actions';
import { Address } from './components/Address';
import { Dimension } from './components/Dimension';
import { IsResidential } from './components/IsResidential';
import { ShippingDate } from './components/ShippingDate';
import { Weight } from './components/Weight';
import { RateFields } from './enum';

export const initialValues = {
  [RateFields.LENGTH]: undefined,
  [RateFields.WIDTH]: undefined,
  [RateFields.HEIGHT]: undefined,
  [RateFields.WEIGHT]: undefined,
  [RateFields.RESIDENTIAL]: true,
  [RateFields.SHIPPING_POSTAL_CODE]: '',
  [RateFields.RECIPIENT_POSTAL_CODE]: '',
  [RateFields.SHIP_DATE_STAMP]: initialShipDate(),
};

export const Form: React.FC = () => {
  return (
    <FormikForm>
      <Address />
      <IsResidential />
      <Dimension />
      <Weight />
      <ShippingDate />
      <Actions />
    </FormikForm>
  );
};

export default Form;
