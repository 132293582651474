/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HubHoursOperationalHours,
    HubHoursOperationalHoursFromJSON,
    HubHoursOperationalHoursFromJSONTyped,
    HubHoursOperationalHoursToJSON,
} from './HubHoursOperationalHours';
import {
    OperationalHoursTypeEnum,
    OperationalHoursTypeEnumFromJSON,
    OperationalHoursTypeEnumFromJSONTyped,
    OperationalHoursTypeEnumToJSON,
} from './OperationalHoursTypeEnum';

/**
 * 
 * @export
 * @interface HubHours
 */
export interface HubHours {
    /**
     * 
     * @type {string}
     * @memberof HubHours
     */
    dayOfWeek: string;
    /**
     * 
     * @type {HubHoursOperationalHours}
     * @memberof HubHours
     */
    operationalHours?: HubHoursOperationalHours | null;
    /**
     * 
     * @type {OperationalHoursTypeEnum}
     * @memberof HubHours
     */
    operationalHoursType: OperationalHoursTypeEnum;
}

export function HubHoursFromJSON(json: any): HubHours {
    return HubHoursFromJSONTyped(json, false);
}

export function HubHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): HubHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayOfWeek': json['day_of_week'],
        'operationalHours': !exists(json, 'operational_hours') ? undefined : HubHoursOperationalHoursFromJSON(json['operational_hours']),
        'operationalHoursType': OperationalHoursTypeEnumFromJSON(json['operational_hours_type']),
    };
}

export function HubHoursToJSON(value?: HubHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day_of_week': value.dayOfWeek,
        'operational_hours': HubHoursOperationalHoursToJSON(value.operationalHours),
        'operational_hours_type': OperationalHoursTypeEnumToJSON(value.operationalHoursType),
    };
}

