export enum AddressFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY = 'company',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  CITY = 'city',
  STATE = 'state',
  POSTAL_CODE = 'postalCode',
  PHONE = 'phone',
  EMAIL = 'email',
  IS_RESIDENTIAL = 'isResidential',
}

export enum AddressSide {
  SHIPPER = 'shipper',
  RECIPIENT = 'recipient',
}

export enum StandarizedAddressSide {
  SHIPPER_STD = 'shipperStandarized',
  RECIPIENT_STD = 'recipientStandarized',
}

export enum ShipModalState {
  START = 'start',
  RECIPIENT_ADDRESS = 'Recipient Address',
  RECIPIENT_HUB = 'Select Hub',
  RECIPIENT_HUB_SEARCH = 'FedEx Facility',
  WEATHER = 'Weather',
  SHARE_ADDRESS = 'Share Address',
  VALIDATE_ADDRESS = 'Validate Address',
  INVALID_ADDRESS = 'Incomplete Address',
  DROP_OFF_LOCATIONS = 'Drop Off Locations',
  CREATE_ADDRESS_VIA_MESSENGER = 'Create Address',
}
