import { useCallback, useState } from 'react';
import { AddressOnlyT } from 'pages/Ship/Generic/helpers/types';

import {
  convertUserAddressToHubAddressT,
  convertUserAddressToPerson,
} from 'pages/Ship/UserShippingAddress/helpers/transformFedexHubData';
import { generateSingletonHook } from 'context/SingletonContext';
import { useShipping } from 'api/generated/useShipping';
import { StandardizedLocationDetail, UserAddress } from 'api/sdk';
import { CountryCode } from 'services/localization';
import { HUB_ADDRESS, HUB_SEARCH, useHubSearchSteps } from './useHubsSearchSteps';

/*
CAUTION: Modify this provider with care, because it is coupled with different components & context.
Importantly, don't mix up the logic using other providers in this provider and try to keep it as a single responsibility provider.
*/
const useDecoratedHook = () => {
  const hubSearchSteps = useHubSearchSteps();
  const [isLoading, setIsLoading] = useState(false);
  const { createHubLocations, error } = useShipping();
  const [selectedHubAddress, setSelectedHubAddress] = useState<UserAddress>();
  const [fedexData, setFedexData] = useState<StandardizedLocationDetail[]>([]);

  const searchFedex = async (d: Pick<AddressOnlyT, 'postalCode'>) => {
    setIsLoading(true);
    // Reset the data before fetching new data to avoid showing old/stale data.
    setFedexData([]);
    const fedexData = await createHubLocations({
      request: {
        postalCode: d.postalCode,
        countryCode: CountryCode.US,
        // Info: Following fields are not required.
        // But we need to pass them to respect request schema.
        city: '',
        stateOrProvinceCode: '',
        streetLines: [],
      },
    });
    setIsLoading(false);

    if (!fedexData?.hubs) {
      throw new Error('Error on fetching fedex data');
    }

    setFedexData(fedexData.hubs);
  };

  const onOpenEditHubAddress = useCallback((selectedUserAddress: UserAddress) => {
    if (selectedUserAddress) {
      hubSearchSteps.setStep(HUB_ADDRESS);
      setSelectedHubAddress(selectedUserAddress);
    }
  }, []);

  const onCloseEditHubAddress = useCallback(() => {
    setSelectedHubAddress(undefined);
    hubSearchSteps.setStep(HUB_SEARCH);
    setFedexData([]);
  }, []);

  return {
    error,
    isLoading,
    fedexData,
    hubSearchSteps,
    selectedHubAddress,
    searchFedex,
    onOpenEditHubAddress,
    onCloseEditHubAddress,
    person: selectedHubAddress ? convertUserAddressToPerson(selectedHubAddress) : undefined,
    hub: selectedHubAddress ? convertUserAddressToHubAddressT(selectedHubAddress) : undefined,
  };
};

const [HubAddressProvider, useHubAddress] = generateSingletonHook(useDecoratedHook);
export { HubAddressProvider, useHubAddress };
