/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    trackingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    provider: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    serviceType: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    state?: string;
    /**
     * 
     * @type {Date}
     * @memberof Shipment
     */
    deliveryDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    recipientName: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    readonly labelFileUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    isInsured?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    readonly id: number;
}

export function ShipmentFromJSON(json: any): Shipment {
    return ShipmentFromJSONTyped(json, false);
}

export function ShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackingNumber': json['tracking_number'],
        'provider': json['provider'],
        'serviceType': json['service_type'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'deliveryDate': !exists(json, 'delivery_date') ? undefined : (json['delivery_date'] === null ? null : new Date(json['delivery_date'])),
        'price': json['price'],
        'recipientName': json['recipient_name'],
        'labelFileUrl': json['label_file_url'],
        'isInsured': !exists(json, 'is_insured') ? undefined : json['is_insured'],
        'id': json['id'],
    };
}

export function ShipmentToJSON(value?: Shipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tracking_number': value.trackingNumber,
        'provider': value.provider,
        'service_type': value.serviceType,
        'state': value.state,
        'delivery_date': value.deliveryDate === undefined ? undefined : (value.deliveryDate === null ? null : value.deliveryDate.toISOString().substr(0,10)),
        'price': value.price,
        'recipient_name': value.recipientName,
        'is_insured': value.isInsured,
    };
}

