import { useCallback, useEffect, useState } from 'react';
import { initialPerson } from 'pages/Ship/Generic/Form/helpers/initialValues';
import { useModal } from 'pages/Ship/Generic/Form/hooks/useModal';
import { useShareAddress } from 'pages/Ship/MessengerIntegration/ShareAddress/hooks/useShareAddress';
import { generateSingletonHook } from 'context/SingletonContext';
import { useAccount } from 'api/generated/useAccount';
import { ExtendedAddressT } from '../helpers/transformShippingAddressData';

export const ADD_TITLE = 'Add Shipping Address';
export const EDIT_TITLE = 'Edit Shipping Address';
export const ADD_OR_SHARE_TITLE = 'Add and Share Shipping Address';

export const useDecoratedEditShippingAddress = () => {
  const { addresses } = useAccount();
  const { hasInquiryId } = useShareAddress();
  const { modalStep, handleModalStep, onClose } = useModal();
  const [modalTitle, setModalTitle] = useState<string>(ADD_TITLE);
  const [selectedUserAddress, setSelectedUserAddress] = useState<ExtendedAddressT>();
  const [standardizedAddress, setStandardizedAddress] = useState<ExtendedAddressT>(
    initialPerson as ExtendedAddressT
  );

  useEffect(() => {
    void addresses.refetch();
  }, []);

  useEffect(() => {
    if (!hasInquiryId) return;
    setModalTitle(hasInquiryId ? ADD_OR_SHARE_TITLE : ADD_TITLE);
  }, [hasInquiryId]);

  const refreshAddresses = useCallback(async () => {
    await addresses.refetch();
  }, [addresses]);

  return {
    modalStep,
    modalTitle,
    hasInquiryId,
    selectedUserAddress,
    standardizedAddress,
    userAddresses: addresses.data,
    hasShippingAddress: (addresses.data ?? []).length > 0,
    actions: {
      setModalTitle,
      refreshAddresses,
      handleModalStep,
      setSelectedUserAddress,
      onCloseModal: onClose,
      setStandardizedAddress,
    },
  };
};

const [UserShippingAddressProvider, useUserShippingAddress] = generateSingletonHook(
  useDecoratedEditShippingAddress
);
export { UserShippingAddressProvider, useUserShippingAddress };
