import {
  AddressOnlyT,
  DistanceT,
  HubAddressT,
  PersonContactT,
} from 'pages/Ship/Generic/helpers/types';
import { StandardizedLocationDetail, UserAddress } from 'api/sdk';
import { ShippingProviderEnum } from 'api/sdk/models/ShippingProviderEnum';
import { CountryCode } from 'services/localization';
import { unmaskUSPhoneNumber } from 'services/phoneFormat';

export const convertFedexHubDataToHubAddressT = (
  hubAddressDetails: StandardizedLocationDetail
): HubAddressT => {
  const { displayName } = hubAddressDetails.contact;
  const { phoneNumber } = hubAddressDetails.contact;
  const { locationId } = hubAddressDetails;

  const addressLines = hubAddressDetails.address.streetLines;
  const addressLine1 = addressLines?.[0] ?? '';
  const addressLine2 = addressLines?.[1] ?? '';

  const { city } = hubAddressDetails.address;
  const state = hubAddressDetails.address.stateOrProvinceCode;
  const { postalCode } = hubAddressDetails.address;

  const distance: DistanceT = {
    value: hubAddressDetails.distance.value,
    units: hubAddressDetails.distance.units,
  };

  const address: AddressOnlyT = {
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
  };

  return {
    address,
    distance,
    locationId: locationId,
    displayName: displayName ?? '',
    phoneNumber: phoneNumber ?? '',
    hubHours: hubAddressDetails.hubHours || [],
    providerName: ShippingProviderEnum.Fedex,
  };
};

export const convertHubAddressTToUserAddress = (
  hubAddressWithPersonDetails: HubAddressT & PersonContactT
): Omit<UserAddress, 'id'> => {
  return {
    isResidential: false,
    countryCode: CountryCode.US,
    shippingProvider: hubAddressWithPersonDetails.providerName,
    city: hubAddressWithPersonDetails.address.city,
    postalCode: hubAddressWithPersonDetails.address.postalCode,
    fedexHubLocationId: hubAddressWithPersonDetails.locationId,
    companyName: hubAddressWithPersonDetails.company || null,
    streetLine1: hubAddressWithPersonDetails.address.addressLine1,
    stateOrProvinceCode: hubAddressWithPersonDetails.address.state,
    streetLine2: hubAddressWithPersonDetails.address.addressLine2 || '',
    phone: hubAddressWithPersonDetails.phoneNumber
      ? unmaskUSPhoneNumber(hubAddressWithPersonDetails.phoneNumber)
      : '',
    firstName: hubAddressWithPersonDetails.firstName,
    lastName: hubAddressWithPersonDetails.lastName,
    email: hubAddressWithPersonDetails.email,
  };
};

export const convertUserAddressToHubAddressT = (userAddress: UserAddress): HubAddressT => {
  const {
    fedexHubLocationId,
    shippingProvider,
    phone,
    streetLine1,
    streetLine2,
    city,
    stateOrProvinceCode,
    postalCode,
  } = userAddress;

  const address: AddressOnlyT = {
    city,
    postalCode,
    state: stateOrProvinceCode,
    addressLine1: streetLine1,
    addressLine2: streetLine2 || '',
  };

  const hubAddress: HubAddressT = {
    address,
    locationId: fedexHubLocationId ?? '',
    phoneNumber: phone ? unmaskUSPhoneNumber(phone) : '',
    providerName: shippingProvider as ShippingProviderEnum,
  };

  return hubAddress;
};

export const convertUserAddressToPerson = (userAddress: UserAddress): PersonContactT => {
  const { firstName, lastName, phone, email, companyName } = userAddress;

  return {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    company: companyName || '',
    phone: phone ? unmaskUSPhoneNumber(phone) : '',
  };
};
