import { Box, Typography } from '@mui/material';
import { HubHours } from 'api/sdk/models/HubHours';
import { convertTimeTo12HourFormat } from 'services/formatters';
import { toTitleCase } from 'services/strings';

interface DisplayHubOpenHoursProps {
  hubHours: HubHours[];
}

const DisplayHubOpenHours = ({ hubHours }: DisplayHubOpenHoursProps) => {
  if (hubHours.length === 0) return null;

  const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  const today = daysOfWeek[new Date().getDay()];

  return (
    <Box mt={2} gap={1}>
      {hubHours.map((hubHour) => {
        const begins = convertTimeTo12HourFormat(hubHour.operationalHours?.begins);
        const ends = convertTimeTo12HourFormat(hubHour.operationalHours?.ends);
        const isClosed = !begins && !ends;
        return (
          <Box
            key={hubHour.dayOfWeek}
            sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box>
              <Typography
                variant="body1"
                fontWeight={
                  hubHour.dayOfWeek.toLowerCase() === today.toLowerCase() ? 'bold' : 'normal'
                }
              >
                {toTitleCase(hubHour.dayOfWeek)}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontWeight={
                  hubHour.dayOfWeek.toLowerCase() === today.toLowerCase() ? 'bold' : 'normal'
                }
                variant="body1"
              >
                {isClosed ? 'Closed' : `${begins} - ${ends}`}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default DisplayHubOpenHours;
