/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PatchedUserAddressRequestShippingProvider,
    PatchedUserAddressRequestShippingProviderFromJSON,
    PatchedUserAddressRequestShippingProviderFromJSONTyped,
    PatchedUserAddressRequestShippingProviderToJSON,
} from './PatchedUserAddressRequestShippingProvider';

/**
 * 
 * @export
 * @interface UserAddressRequest
 */
export interface UserAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    streetLine1: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    streetLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    stateOrProvinceCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    countryCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressRequest
     */
    isResidential?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressRequest
     */
    isDefault?: boolean;
    /**
     * 
     * @type {PatchedUserAddressRequestShippingProvider}
     * @memberof UserAddressRequest
     */
    shippingProvider?: PatchedUserAddressRequestShippingProvider | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    fedexHubLocationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressRequest
     */
    phone?: string;
}

export function UserAddressRequestFromJSON(json: any): UserAddressRequest {
    return UserAddressRequestFromJSONTyped(json, false);
}

export function UserAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'companyName': !exists(json, 'company_name') ? undefined : json['company_name'],
        'streetLine1': json['street_line_1'],
        'streetLine2': !exists(json, 'street_line_2') ? undefined : json['street_line_2'],
        'city': json['city'],
        'stateOrProvinceCode': json['state_or_province_code'],
        'postalCode': json['postal_code'],
        'countryCode': json['country_code'],
        'isResidential': !exists(json, 'is_residential') ? undefined : json['is_residential'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'shippingProvider': !exists(json, 'shipping_provider') ? undefined : PatchedUserAddressRequestShippingProviderFromJSON(json['shipping_provider']),
        'fedexHubLocationId': !exists(json, 'fedex_hub_location_id') ? undefined : json['fedex_hub_location_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function UserAddressRequestToJSON(value?: UserAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'company_name': value.companyName,
        'street_line_1': value.streetLine1,
        'street_line_2': value.streetLine2,
        'city': value.city,
        'state_or_province_code': value.stateOrProvinceCode,
        'postal_code': value.postalCode,
        'country_code': value.countryCode,
        'is_residential': value.isResidential,
        'is_default': value.isDefault,
        'shipping_provider': PatchedUserAddressRequestShippingProviderToJSON(value.shippingProvider),
        'fedex_hub_location_id': value.fedexHubLocationId,
        'email': value.email,
        'phone': value.phone,
    };
}

