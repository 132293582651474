import { FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import { Field } from 'formik';
import { RadioGroup } from 'formik-mui';
import SectionTitle from 'pages/Ship/Generic/Form/SectionTitle';
import { LabelFileTypeEnum } from 'api/sdk';
import { ShippingLabelPrinterPreference } from 'services/phrase';
import { LabelGeneralFields } from '../../helpers/enum';
import { useLabelPrinterType } from '../../hooks/useLabelPrinterType';
import styles from './LabelFileOptions.module.scss';

export const LabelFileOptions: React.FC = () => {
  useLabelPrinterType();
  return (
    <div>
      <SectionTitle title="Label File Type" />
      <Box className={styles.labelFileOptionWrapper}>
        <Field name={LabelGeneralFields.LABEL_FILE_TYPE} component={RadioGroup}>
          <FormControlLabel
            control={<Radio />}
            value={LabelFileTypeEnum.Pdf}
            label={ShippingLabelPrinterPreference.LASER_INKJET_PDF}
          />
          <FormControlLabel
            control={<Radio />}
            value={LabelFileTypeEnum.Png}
            label={ShippingLabelPrinterPreference.THERMAL_PNG}
          />
        </Field>
      </Box>
    </div>
  );
};
