import { Box } from '@mui/material';
import { AddressSide, ShipModalState } from 'pages/Ship/Generic/enum';
import { useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import DropOffLocations from 'pages/Ship/Shipping/Add/nestedModalSections/DropOffLocations/DropOffLocations';
import { AddressSecondLineConfirmation } from 'pages/Ship/Shipping/Add/nestedModalSections/ValidateAddress/AddressSecondLineConfirmation';
import { DrawerDash } from 'components/DrawerDash/DrawerDash';
import { Drawer } from 'components/experimental/Drawer/Drawer';
import { NewModal } from 'components/NewModal/NewModal';
import SidebarWrapper from 'components/Sidebar/SidebarWrapper';
import { DESKTOP } from 'services/constants/breakpoints';
import RecipientAddressFormDrawer from './RecipientAddressFormDrawer/RecipientAddressFormDrawer';
import RecipientHub from './RecipientHub/RecipientHub';
import { ValidateAddress } from './ValidateAddress/ValidateAddress';
import Weather from './Weather/Weather';
import stacking from 'style/stacking.scss';
import styles from './style.module.scss';

const modalMinWidth = '600px';

const Component = () => {
  const { modalStep } = useShipment();

  switch (modalStep) {
    case ShipModalState.RECIPIENT_ADDRESS:
      return (
        <RecipientAddressFormDrawer
          namePrefix={AddressSide.RECIPIENT}
          key={AddressSide.RECIPIENT}
        />
      );
    case ShipModalState.RECIPIENT_HUB_SEARCH:
      return <RecipientHub />;

    case ShipModalState.VALIDATE_ADDRESS:
      return <ValidateAddress />;

    case ShipModalState.INVALID_ADDRESS:
      return <AddressSecondLineConfirmation />;

    case ShipModalState.WEATHER:
      return <Weather />;

    case ShipModalState.DROP_OFF_LOCATIONS:
      return <DropOffLocations />;

    default:
      return null;
  }
};

const FormNestedSections = () => {
  const width = window.innerWidth;
  const { openNested, modalStep } = useShipment();

  const title = modalStep || '';

  if (width < DESKTOP) {
    return (
      <Drawer
        anchor="bottom"
        isOpen={openNested.isOpen}
        isExpanded
        actions={{ open, close: () => openNested.close() }}
        sx={{
          zIndex: stacking.zindex_dropdown,
        }}
      >
        <DrawerDash />
        <SidebarWrapper title={title} isDrawer isDrawerAutoHeight={true}>
          <div className={styles.contentWrapper}>
            <Component />
          </div>
        </SidebarWrapper>
      </Drawer>
    );
  }

  return (
    <NewModal open={openNested.isOpen} title={title} onClose={openNested.close}>
      <Box minWidth={modalMinWidth}>
        <Component />
      </Box>
    </NewModal>
  );
};

export default FormNestedSections;
