import { useState } from 'react';

export const HUB_SEARCH = Symbol('HUB_SEARCH');
export const HUB_ADDRESS = Symbol('HUB_ADDRESS');
export const HUB_RECIPIENT_DETAILS = Symbol('HUB_RECIPIENT_DETAILS');

export type StepsProps = {
  next?: () => void;
  prev?: () => void;
};

export const initStep = HUB_SEARCH as Steps;

export type Steps = typeof HUB_SEARCH | typeof HUB_ADDRESS | typeof HUB_RECIPIENT_DETAILS;

const stepsInOrder: Steps[] = [HUB_SEARCH, HUB_ADDRESS, HUB_RECIPIENT_DETAILS];

export function moveStepUp(currentStep: Steps): Steps | null {
  const currentIndex = stepsInOrder.indexOf(currentStep);
  const nextIndex = currentIndex + 1;

  if (nextIndex < stepsInOrder.length) {
    return stepsInOrder[nextIndex];
  } else {
    return null; // Reached the last step, cannot move up further
  }
}

export function moveStepDown(currentStep: Steps): Steps | null {
  const currentIndex = stepsInOrder.indexOf(currentStep);
  const previousIndex = currentIndex - 1;

  if (previousIndex >= 0) {
    return stepsInOrder[previousIndex];
  } else {
    return null; // Reached the first step, cannot move down further
  }
}

export const useRecipientHubSteps = (initialStep?: Steps) => {
  const [step, setStep] = useState<Steps>(initialStep || initStep);

  const nextStep = () => {
    const next = moveStepUp(step);
    next && setStep(next);
  };

  const prevStep = () => {
    const prev = moveStepDown(step);
    prev && setStep(prev);
  };

  return {
    step,
    setStep,
    nextStep,
    prevStep,
  };
};
