import { Formik } from 'formik';
import { SearchHubs as SearchHubsForm } from 'pages/Ship/UserShippingAddress/components/EditHubAddress/components/Hubs/SearchHubs';
import { useHubSearch } from 'pages/Ship/UserShippingAddress/components/EditHubAddress/hooks/useHubSearch';
interface SearchHubsProps {
  next: () => void;
}

const SearchHubs = ({ next }: SearchHubsProps) => {
  const { formikBag } = useHubSearch({ nextStep: next });

  return (
    <Formik {...formikBag}>
      <SearchHubsForm formStyles={{ width: '100%' }} />
    </Formik>
  );
};

export default SearchHubs;
