import {
  convertUserAddressToHubAddressT,
  convertUserAddressToPerson,
} from 'pages/Ship/UserShippingAddress/helpers/transformFedexHubData';
import { convertUserAddressToAddressT } from 'pages/Ship/UserShippingAddress/helpers/transformShippingAddressData';
import { UserAddress } from 'api/sdk';
import { AddressT, HubAddressT, PersonContactT } from '../helpers/types';

/*
 * This hook convert user address to address type or hub address type based on fedexHubLocationId.
 */
export const useAddressType = (userAddress?: UserAddress) => {
  let addressT: AddressT | undefined;
  let hubAddressT: HubAddressT | undefined;
  let personContactT: PersonContactT | undefined;

  if (userAddress?.fedexHubLocationId) {
    personContactT = convertUserAddressToPerson(userAddress);
    hubAddressT = convertUserAddressToHubAddressT(userAddress);
  } else if (userAddress) {
    addressT = convertUserAddressToAddressT(userAddress);
  }
  return { addressT, hubAddressT, personContactT };
};
