export const DATEINPUT_FORMAT = 'YYYY-MM-DD'; // Format used by DateInput component
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_US = 'MM/DD/YYYY';
export const SHORT_DATE_FORMAT = 'MM/DD/YY';

export const DAY_MONTH_DAY_FORMAT = 'ddd, MMM D';
export const MONTH_DAY_YEAR_FORMAT = 'MMM D, YYYY';
export const ABBREVIATED_MONTH_FORMAT = 'MMM';
export const ORDINAL_INDICATOR_FORMAT = 'Do';

export const TIME_FORMAT_12_HOUR = 'h:mm A';
export const TIME_FORMAT_24_HOUR = 'hh:mm:ss';

export const MONTH_DAY_YEAR_SHORT_FORMAT = 'MM/DD/YY';

export const INQUIRY_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';
export const MONTH_FORMAT = 'MMM YYYY';
export const MONTH_DAY_FORMAT = 'MMM D';

export const ACTIVITY_DATE_LONG_FORMAT = 'MM/DD/YYYY HH:mm';
export const ACTIVITY_DATE_SHORT_FORMAT = 'MM/DD/YY';
export const LAST_SEEN_FORMAT = 'MMM DD, YYYY hh:mm A';

export const MESSENGER_DATE_FORMAT = 'ddd, MMM D, YYYY';
export const DAY_OF_THE_WEEK = 'dddd';

export const DAY_OF_THE_WEEK_SHORT = 'ddd';
export const DELIVERY_DATE_FORMAT = 'ddd MMM D, YYYY';
