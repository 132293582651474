import { useEffect } from 'react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useFormikContext } from 'formik';
import { AddressSide, ShipModalState } from 'pages/Ship/Generic/enum';
import AddressForm from 'pages/Ship/Generic/Form/AddressForm/AddressForm';
import SectionTitle from 'pages/Ship/Generic/Form/SectionTitle';
import { POSTAL_CODE_MIN_LENGTH } from 'pages/Ship/Generic/helpers/constants';
import { isAddressFilled } from 'pages/Ship/Shipping/Add/helpers/formHelpers';
import { AddressType, LabelFormTypes } from 'pages/Ship/Shipping/Add/helpers/types';
import { useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import styles from './styles.module.scss';

const Shipper = () => {
  const { handleModalStep, setDropOffLocationPostalCode } = useShipment();
  const { values } = useFormikContext<LabelFormTypes>();
  const isFilled = isAddressFilled(values.shipper);

  const shipperPostalCode = values.shipper.postalCode;

  useEffect(() => {
    if (shipperPostalCode && shipperPostalCode.length >= POSTAL_CODE_MIN_LENGTH) {
      setDropOffLocationPostalCode(shipperPostalCode);
    }
  }, [shipperPostalCode]);

  return (
    <Box mb={4}>
      <SectionTitle
        title="From"
        sideChild={
          <>
            <FontAwesomeIcon icon={faSearch} style={{ opacity: 0.3 }} />
            <Box sx={{ ml: 1, opacity: 0.3 }}>Saved Addresses</Box>
          </>
        }
      />
      <AddressForm<AddressType> namePrefix={AddressSide.SHIPPER} includeResidential={false} />
      <div className={styles.buttons}>
        <Button
          disabled={!isFilled}
          size="large"
          onClick={() => handleModalStep(ShipModalState.WEATHER)}
        >
          Weather
        </Button>
        <Button
          disabled={!shipperPostalCode || shipperPostalCode.length < POSTAL_CODE_MIN_LENGTH}
          size="large"
          onClick={() => handleModalStep(ShipModalState.DROP_OFF_LOCATIONS)}
        >
          Drop Off Locations
        </Button>
      </div>
      <p className={styles.alertMessage}>*Check the cutoff time for your drop off location.</p>
    </Box>
  );
};

export default Shipper;
