/**
 * This file was autogenerated and any manual changes will be overwritten eventually.
 * Remove this notice if you move this file out of the src/api/generated/ directory.
 */

import { useAPIHook } from "api/utilities/useAPIHook";
import { WeatherApi } from "../sdk/apis/WeatherApi";
import { configuration } from "./configuration";

export const useWeather = () => {
  const instance = new WeatherApi(configuration);

  const createPostalCodeWeather = useAPIHook(instance.weatherPostalCodeWeatherCreate.bind(instance));

  return {
    error: createPostalCodeWeather.error,
    isLoading: createPostalCodeWeather.isLoading,

    createPostalCodeWeather: createPostalCodeWeather.callback,
  };
};
