/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Laser` - Laser
 * * `Thermal` - Thermal
 * @export
 */
export const PrinterTypeEnum = {
    Laser: 'Laser',
    Thermal: 'Thermal'
} as const;
export type PrinterTypeEnum = typeof PrinterTypeEnum[keyof typeof PrinterTypeEnum];


export function PrinterTypeEnumFromJSON(json: any): PrinterTypeEnum {
    return PrinterTypeEnumFromJSONTyped(json, false);
}

export function PrinterTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrinterTypeEnum {
    return json as PrinterTypeEnum;
}

export function PrinterTypeEnumToJSON(value?: PrinterTypeEnum | null): any {
    return value as any;
}

