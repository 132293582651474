import { useCallback } from 'react';
import { Button } from '@mui/material';
import messengerStyles from 'pages/Messenger/components/SingleThread/Message/styles.module.scss';
import { initialPerson } from 'pages/Ship/Generic/Form/helpers/initialValues';
import { AddressT, RecipientHubDetailsT } from 'pages/Ship/Generic/helpers/types';
import { useAddressType } from 'pages/Ship/Generic/hooks/useAddressType';
import usePreventShipping from 'pages/Ship/Generic/hooks/usePreventShipping';
import { PackageDetailsType } from 'pages/Ship/Shipping/Add/helpers/types';
import { ShipmentProvider, useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import ShipmentModal from 'pages/Ship/Shipping/Add/ShipmentModal';
import { ShipmentsListProvider } from 'pages/Ship/Shipping/List/hooks/useShipmentsList';
import { ServiceTypeEnum } from 'api/sdk';
import { useShippingRates } from '../../hooks/useShippingRates';

type BuyServiceProviderProps = {
  children: React.ReactNode;
};

// CAUTION: Carefully change the type of ServicePurchaseData, because it's is coupled with the CreateLabelComponent & used in formik context values.
export type ServicePurchaseData = {
  // This will be used as fallback if there is no default address for the shipper in the label form.
  shipperPostalCode?: string;
  // Info: Recipient address & hub address are optional and also mutually exclusive.
  recipientAddress?: AddressT;
  recipientHubAddress?: RecipientHubDetailsT;
  serviceType: ServiceTypeEnum;
  packageInfo: PackageDetailsType;
};

export const BuyServiceProvider: React.FC<BuyServiceProviderProps> = ({ children }) => {
  return (
    <ShipmentsListProvider>
      <ShipmentProvider>{children}</ShipmentProvider>
    </ShipmentsListProvider>
  );
};

type CreateLabelProps = { servicePurchaseData: ServicePurchaseData; actionLabel: string };

const BuyServiceComponent: React.FC<CreateLabelProps> = ({ servicePurchaseData, actionLabel }) => {
  const { handleOpenLabelCreationModal } = useShipment();
  const { recipientAddress } = useShippingRates();
  const { addressT, hubAddressT, personContactT } = useAddressType(recipientAddress);

  const { checkIfShippingAllowed } = usePreventShipping();

  const modifiedServicePurchaseData = {
    ...servicePurchaseData,
    recipientAddress: addressT,
  };

  if (hubAddressT) {
    modifiedServicePurchaseData.recipientHubAddress = {
      ...hubAddressT,
      recipient: personContactT ?? initialPerson,
    };
  }

  const handleCreateLabel = useCallback(() => {
    if (!servicePurchaseData) return;
    if (!checkIfShippingAllowed()) return;
    handleOpenLabelCreationModal(modifiedServicePurchaseData);
  }, [servicePurchaseData]);

  return (
    <>
      <Button
        fullWidth
        size="large"
        color="primary"
        variant="contained"
        onClick={handleCreateLabel}
        className={messengerStyles.createLabel}
      >
        {actionLabel}
      </Button>
      <ShipmentModal />
    </>
  );
};

export const BuyService: React.FC<CreateLabelProps> = ({ servicePurchaseData, actionLabel }) => {
  return (
    <BuyServiceProvider>
      <BuyServiceComponent servicePurchaseData={servicePurchaseData} actionLabel={actionLabel} />
    </BuyServiceProvider>
  );
};
