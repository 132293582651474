import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { LabelFileTypeEnum, PrinterTypeEnum } from 'api/sdk';
import { LabelGeneralFields } from '../helpers/enum';
import { LabelFormTypes } from '../helpers/types';

/*
 * This hook is used to determine the printer type based on the file type selected.
 * This field is hidden in the UI and is used to determine the printer type based on the file type selected.
 * And update the formik values accordingly.
 *  Currently, we support following combinations:
 * 1. Thermal printer: 4x6 .PNG file
 * 2. Laser printer: "PAPER_LETTER" .PDF file
 * In future, we may add, 3. Thermal printer: 4x6 .PDF file
 */
export const useLabelPrinterType = () => {
  const { values, setFieldValue } = useFormikContext<LabelFormTypes>();

  useEffect(() => {
    switch (values[LabelGeneralFields.LABEL_FILE_TYPE]) {
      case LabelFileTypeEnum.Pdf:
        return void setFieldValue(LabelGeneralFields.LABEL_PRINTER_TYPE, PrinterTypeEnum.Laser);
      case LabelFileTypeEnum.Png:
        return void setFieldValue(LabelGeneralFields.LABEL_PRINTER_TYPE, PrinterTypeEnum.Thermal);
      default:
        console.error(
          'No printer type found for the selected file type',
          values[LabelGeneralFields.LABEL_FILE_TYPE]
        );
    }
  }, [setFieldValue, values]);
};
