/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PDF` - PDF
 * * `PNG` - PNG
 * @export
 */
export const LabelFileTypeEnum = {
    Pdf: 'PDF',
    Png: 'PNG'
} as const;
export type LabelFileTypeEnum = typeof LabelFileTypeEnum[keyof typeof LabelFileTypeEnum];


export function LabelFileTypeEnumFromJSON(json: any): LabelFileTypeEnum {
    return LabelFileTypeEnumFromJSONTyped(json, false);
}

export function LabelFileTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelFileTypeEnum {
    return json as LabelFileTypeEnum;
}

export function LabelFileTypeEnumToJSON(value?: LabelFileTypeEnum | null): any {
    return value as any;
}

