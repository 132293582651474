/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BaseWeatherRequestRequest,
  BaseWeatherRequestRequestFromJSON,
  BaseWeatherRequestRequestToJSON,
  BaseWeatherResponse,
  BaseWeatherResponseFromJSON,
  BaseWeatherResponseToJSON,
} from "../models";

export interface WeatherPostalCodeWeatherCreateRequest {
  /** @deprecated Use request instead. **/
  baseWeatherRequestRequest?: BaseWeatherRequestRequest;
  request: BaseWeatherRequestRequest;
}

/**
 *
 */
export class WeatherApi extends runtime.BaseAPI {
  /**
   */
  async weatherPostalCodeWeatherCreateRaw(
    requestParameters: WeatherPostalCodeWeatherCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BaseWeatherResponse>> {
    if (
      (requestParameters.request || requestParameters.baseWeatherRequestRequest) === null ||
      (requestParameters.request || requestParameters.baseWeatherRequestRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "baseWeatherRequestRequest",
        "Required parameter (requestParameters.request || requestParameters.baseWeatherRequestRequest) was null or undefined when calling weatherPostalCodeWeatherCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/weather/postal_code_weather/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BaseWeatherRequestRequestToJSON(requestParameters.request || requestParameters.baseWeatherRequestRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BaseWeatherResponseFromJSON(jsonValue));
  }

  /**
   */
  async weatherPostalCodeWeatherCreate(
    requestParameters: WeatherPostalCodeWeatherCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BaseWeatherResponse> {
    const response = await this.weatherPostalCodeWeatherCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
