/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Temperature
 */
export interface Temperature {
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof Temperature
     */
    unit: string;
}

export function TemperatureFromJSON(json: any): Temperature {
    return TemperatureFromJSONTyped(json, false);
}

export function TemperatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Temperature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'unit': json['unit'],
    };
}

export function TemperatureToJSON(value?: Temperature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'unit': value.unit,
    };
}

