import { useState } from 'react';

export const HUB_SEARCH = Symbol('HUB_SEARCH');
export const HUB_SELECT = Symbol('HUB_SELECT');
export const HUB_ADDRESS = Symbol('HUB_ADDRESS');

export const getStepTitle = (step: Steps) => {
  switch (step) {
    case HUB_SEARCH:
      return 'Search Hubs';
    case HUB_SELECT:
      return 'Select Hub';
    case HUB_ADDRESS:
      return 'Hub Address';
    default:
      console.error('Unknown step', step);
      return '';
  }
};

export type StepsProps = {
  next?: () => void;
  prev?: () => void;
};

export const initStep = HUB_SEARCH as Steps;

export type Steps = typeof HUB_SEARCH | typeof HUB_SELECT | typeof HUB_ADDRESS;

const stepsInOrder: Steps[] = [HUB_SEARCH, HUB_SELECT, HUB_ADDRESS];

export function moveStepUp(currentStep: Steps): Steps | null {
  const currentIndex = stepsInOrder.indexOf(currentStep);
  const nextIndex = currentIndex + 1;

  if (nextIndex < stepsInOrder.length) {
    return stepsInOrder[nextIndex];
  } else {
    return null; // Reached the last step, cannot move up further
  }
}

export function moveStepDown(currentStep: Steps): Steps | null {
  const currentIndex = stepsInOrder.indexOf(currentStep);
  const previousIndex = currentIndex - 1;

  if (previousIndex >= 0) {
    return stepsInOrder[previousIndex];
  } else {
    return null; // Reached the first step, cannot move down further
  }
}

export const useHubSearchSteps = () => {
  const [step, setStep] = useState<Steps>(initStep);

  const nextStep = () => {
    const next = moveStepUp(step);
    next && setStep(next);
  };

  const prevStep = () => {
    const prev = moveStepDown(step);
    prev && setStep(prev);
  };

  return {
    step,
    setStep,
    nextStep,
    prevStep,
  };
};
