/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseWeather,
    BaseWeatherFromJSON,
    BaseWeatherFromJSONTyped,
    BaseWeatherToJSON,
} from './BaseWeather';
import {
    DailyWeatherAtPostcode,
    DailyWeatherAtPostcodeFromJSON,
    DailyWeatherAtPostcodeFromJSONTyped,
    DailyWeatherAtPostcodeToJSON,
} from './DailyWeatherAtPostcode';

/**
 * 
 * @export
 * @interface BaseWeatherResponsePostalCode1Weather
 */
export interface BaseWeatherResponsePostalCode1Weather {
    /**
     * 
     * @type {string}
     * @memberof BaseWeatherResponsePostalCode1Weather
     */
    name: string;
    /**
     * 
     * @type {Array<DailyWeatherAtPostcode>}
     * @memberof BaseWeatherResponsePostalCode1Weather
     */
    weather: Array<DailyWeatherAtPostcode>;
}

export function BaseWeatherResponsePostalCode1WeatherFromJSON(json: any): BaseWeatherResponsePostalCode1Weather {
    return BaseWeatherResponsePostalCode1WeatherFromJSONTyped(json, false);
}

export function BaseWeatherResponsePostalCode1WeatherFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseWeatherResponsePostalCode1Weather {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'weather': ((json['weather'] as Array<any>).map(DailyWeatherAtPostcodeFromJSON)),
    };
}

export function BaseWeatherResponsePostalCode1WeatherToJSON(value?: BaseWeatherResponsePostalCode1Weather | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'weather': ((value.weather as Array<any>).map(DailyWeatherAtPostcodeToJSON)),
    };
}

