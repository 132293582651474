import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AddressFields, AddressSide } from 'pages/Ship/Generic/enum';
import { ContactDetails, PersonDetails } from 'pages/Ship/Generic/Form/AddressForm/AddressForm';
import { initialPerson } from 'pages/Ship/Generic/Form/helpers/initialValues';
import { personValidationSchema } from 'pages/Ship/Generic/Form/helpers/validation';
import { HubAddressT } from 'pages/Ship/Generic/helpers/types';
import { AddressType } from 'pages/Ship/Shipping/Add/helpers/types';
import { useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import { HubAddress } from 'pages/Ship/UserShippingAddress/components/EditHubAddress/components/Hubs/HubAddress';

interface DetailsHubsProps {
  prev: () => void;
  next: () => void;
  selectedHub: HubAddressT;
}

interface DetailsHubsValues {
  [AddressSide.RECIPIENT]: {
    [AddressFields.FIRST_NAME]: string;
    [AddressFields.LAST_NAME]: string;
    [AddressFields.COMPANY]: string;
    [AddressFields.PHONE]: string;
    [AddressFields.EMAIL]: string;
  };
}

const schema = Yup.object({
  [AddressSide.RECIPIENT]: Yup.object({
    ...personValidationSchema.fields,
  }),
});

const DetailsHubs = ({ prev, next, selectedHub }: DetailsHubsProps) => {
  const { setRecipientHubAddress, recipientHubAddress, setRecipientAddress } = useShipment();
  const handleSubmit = (values: DetailsHubsValues) => {
    const { firstName, lastName, company, phone, email } = values[AddressSide.RECIPIENT];

    setRecipientHubAddress({
      recipient: {
        firstName,
        lastName,
        company,
        phone,
        email,
      },
      ...selectedHub,
    });
    // clear recipient address
    // we can have only one: address or hub address
    setRecipientAddress(initialPerson);
    next();
  };

  return (
    <Box>
      <HubAddress hubAddress={selectedHub} onEdit={() => prev()} />
      <Box sx={{ mt: 3 }}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={schema}
          initialValues={{
            [AddressSide.RECIPIENT]: {
              [AddressFields.FIRST_NAME]: recipientHubAddress?.recipient?.firstName || '',
              [AddressFields.LAST_NAME]: recipientHubAddress?.recipient?.lastName || '',
              [AddressFields.COMPANY]: recipientHubAddress?.recipient?.company || '',
              [AddressFields.PHONE]: recipientHubAddress?.recipient?.phone || '',
              [AddressFields.EMAIL]: recipientHubAddress?.recipient?.email || '',
            },
          }}
        >
          <Form>
            <PersonDetails<AddressType> namePrefix={AddressSide.RECIPIENT} />
            <ContactDetails<AddressType> namePrefix={AddressSide.RECIPIENT} />
            <Box gap={2} display="flex">
              <Button
                type="button"
                variant="outlined"
                size="large"
                sx={{ width: '50%' }}
                onClick={prev}
              >
                Back
              </Button>
              <Button type="submit" variant="contained" size="large" sx={{ width: '50%' }}>
                Save
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default DetailsHubs;
