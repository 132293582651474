import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import { WhenWrapper } from 'components/experimental/Wrappers/WhenWrapper';
import Spinner from 'components/Spinner/Spinner';
import { useWeather } from 'api/generated/useWeather';
import { BaseWeather, BaseWeatherResponse } from 'api/sdk';
import { DAY_OF_THE_WEEK_SHORT, MONTH_DAY_FORMAT } from 'services/constants';
import styles from './style.module.scss';

const WeatherSection: React.FC<{
  data: BaseWeather | null;
  errorTitle: string;
}> = ({ data, errorTitle }) => {
  // Null means there was an error in Weather Provider
  if (data === null) {
    return (
      <div className={styles.weatherSection}>
        <b className={classnames(styles.title, styles.noBorder)}>{errorTitle}</b>
      </div>
    );
  }

  // Undefined means there is no hub weather data in response (recipient details)
  if (!data) return null;

  return (
    <div className={styles.weatherSection}>
      <b className={styles.title}>{data?.name} Forecast</b>
      <div className={styles.list}>
        {data.weather.map((item, index) => {
          const day = dayjs(item.date).format(DAY_OF_THE_WEEK_SHORT);
          const monthAndDay = dayjs(item.date).format(MONTH_DAY_FORMAT);
          const isToday = dayjs(item.date).isSame(dayjs(), 'day');
          return (
            <div className={styles.listItem} key={index}>
              <div className={styles.date}>
                {isToday ? (
                  <span className={styles.day}>Today</span>
                ) : (
                  <>
                    <span className={styles.day}>{day},</span>
                    <span>{monthAndDay}</span>
                  </>
                )}
              </div>
              <div className={styles.temperatureWrapper}>
                <div className={styles.min}>
                  {item.temperatureMin.value} {item.temperatureMin.unit}
                </div>
                <div className={styles.max}>
                  {item.temperatureMax.value} {item.temperatureMax.unit}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Weather = () => {
  const { dropOffLocationPostalCode, recipientAddress, recipientHubAddress } = useShipment();
  const { createPostalCodeWeather, isLoading, error } = useWeather();
  const [weather, setWeather] = useState<BaseWeatherResponse | undefined>(undefined);
  const handleGetWeather = async () => {
    if (!dropOffLocationPostalCode) return;
    const weather = await createPostalCodeWeather({
      request: {
        postalCode1: `${dropOffLocationPostalCode}`,
        postalCode2:
          recipientAddress?.postalCode || recipientHubAddress?.address?.postalCode || undefined,
      },
    });
    weather && setWeather(weather);
  };

  useEffect(() => {
    if (!weather) {
      void handleGetWeather();
    }
    return () => {
      setWeather(undefined);
    };
  }, []);

  return (
    <Box style={{ width: '100%' }}>
      <WhenWrapper condition={isLoading}>
        <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: 32 }}>
          <Spinner />
        </Box>
      </WhenWrapper>
      {error && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="h6" sx={{ margin: 0, textAlign: 'center' }}>
            Error fetching weather data
          </Typography>
          <Button onClick={() => void handleGetWeather()}>Try again</Button>
        </Box>
      )}

      {!isLoading && weather && (
        <div className={styles.weatherContainer}>
          <WeatherSection
            data={weather.postalCode1Weather}
            errorTitle={'We could not get weather for your location'}
          />
          <WeatherSection
            data={weather.postalCode2Weather}
            errorTitle={'We could not get weather for the recipient location'}
          />
          <WeatherSection
            data={weather.indianapolisHubWeather}
            errorTitle="We could not get weather for the Indianapolis Hub"
          />
          <WeatherSection
            data={weather.memphisHubWeather}
            errorTitle="We could not get weather for the Memphis Hub"
          />
        </div>
      )}
    </Box>
  );
};

export default Weather;
