import { useCallback } from 'react';
import { Box } from '@mui/material';
import { initialPerson } from 'pages/Ship/Generic/Form/helpers/initialValues';
import { HubAddressT } from 'pages/Ship/Generic/helpers/types';
import { useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import {
  HubAddressProvider,
  useHubAddress,
} from 'pages/Ship/UserShippingAddress/components/EditHubAddress/hooks/useHubAddress';

import DetailsHubs from './components/DetailsHubs';
import SearchHubs from './components/SearchHubs';
import SelectHubs from './components/SelectHubs';
import {
  HUB_SEARCH,
  HUB_ADDRESS,
  HUB_RECIPIENT_DETAILS,
  useRecipientHubSteps,
} from './hooks/useRecipientHubSteps';
import styles from './style.module.scss';

const Component = () => {
  const { fedexData } = useHubAddress();
  const { openNested, setRecipientAddress, recipientHubAddress, selectedHub, setSelectedHub } =
    useShipment();
  const { step, setStep, nextStep, prevStep } = useRecipientHubSteps(
    recipientHubAddress ? HUB_RECIPIENT_DETAILS : HUB_SEARCH
  );

  const handleHubSelection = (hub: HubAddressT) => {
    setSelectedHub(hub);
    nextStep();
  };

  const handleFinish = () => {
    // clear the recipient address if hub selected
    setRecipientAddress(initialPerson);
    openNested.close();
  };
  const prevStepOnHubDetails = useCallback(() => {
    if (fedexData.length) {
      prevStep();
    } else {
      setStep(HUB_SEARCH);
    }
  }, [fedexData, prevStep, setStep]);

  if (step === HUB_SEARCH) return <SearchHubs next={nextStep} />;

  if (step === HUB_ADDRESS) return <SelectHubs prev={prevStep} next={handleHubSelection} />;

  if (step === HUB_RECIPIENT_DETAILS && selectedHub)
    return (
      <DetailsHubs prev={prevStepOnHubDetails} next={handleFinish} selectedHub={selectedHub} />
    );

  return null;
};

const RecipientHub = () => {
  return (
    <HubAddressProvider>
      <Box className={styles.outer}>
        <Component />
      </Box>
    </HubAddressProvider>
  );
};

export default RecipientHub;
