import React, { useState } from 'react';
import { Box, Button, DialogActions } from '@mui/material';
import { HubAddressT } from 'pages/Ship/Generic/helpers/types';
import { convertFedexHubDataToHubAddressT } from 'pages/Ship/UserShippingAddress/helpers/transformFedexHubData';
import { WhenWrapper } from 'components/experimental/Wrappers';
import { useHubAddress } from '../../hooks/useHubAddress';
import { StepsProps } from '../../hooks/useHubsSearchSteps';
import { HubAddress } from './HubAddress';
import styles from '../../EditHubAddress.module.scss';

interface HubAddressProps extends StepsProps {
  selectHub: (hub: HubAddressT) => Promise<void>;
}

const HubList: React.FC<HubAddressProps> = ({ prev, selectHub }) => {
  const { fedexData, error } = useHubAddress();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelectHub = async (hub: HubAddressT) => {
    if (!hub) return;
    setIsSubmitting(true);
    try {
      await selectHub(hub);
    } catch (error) {
      console.error('Error creating address', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const hubAddresses: HubAddressT[] = React.useMemo(
    () => fedexData.map(convertFedexHubDataToHubAddressT),
    [fedexData]
  );

  const isLastHub = (index: number) => index === hubAddresses.length - 1;

  return (
    <>
      {hubAddresses?.map((hubAddress, index) => (
        <Box key={hubAddress.locationId} mt={2} pb={isLastHub(index) ? 8 : 0}>
          <HubAddress hubAddress={hubAddress} onSelect={() => void handleSelectHub(hubAddress)} />
        </Box>
      ))}
      <WhenWrapper condition={hubAddresses.length === 0 || error}>
        <Box mb={4}>
          <p className={styles.noHubsText}>No hubs found</p>
        </Box>
      </WhenWrapper>
      <DialogActions className={styles.editAddressButtonWrapper}>
        <Button variant="contained" size="large" fullWidth onClick={prev} disabled={isSubmitting}>
          Edit Address
        </Button>
      </DialogActions>
    </>
  );
};

export default HubList;
