/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `already_responded` - Already Responded
 * * `mark_purchased` - Mark Purchased
 * * `mark_sold` - Mark Sold
 * * `request_buyer_review` - Request Feedback
 * * `mark_on_hold` - Mark On Hold
 * * `not_available` - Not Available
 * * `decline` - Decline Inquiry
 * * `reopen` - Reopen
 * * `block` - Block User
 * * `unblock` - Unblock User
 * * `archive` - Archive
 * * `unarchive` - Unarchive
 * * `auction_report` - Report Auction
 * * `auction_offer_to_runner_up` - Offer to Runner-Up
 * * `auction_accept_offer` - Accept Offer
 * * `mark_as_shipped` - Mark as Shipped
 * * `mark_as_received` - Mark as Received
 * * `report_message` - Report
 * * `shipping_request_address` - Request Address
 * * `buyer_review` - Rate This
 * * `seller_review` - Rate This
 * @export
 */
export const AvailableActionCodesEnum = {
    AlreadyResponded: 'already_responded',
    MarkPurchased: 'mark_purchased',
    MarkSold: 'mark_sold',
    RequestBuyerReview: 'request_buyer_review',
    MarkOnHold: 'mark_on_hold',
    NotAvailable: 'not_available',
    Decline: 'decline',
    Reopen: 'reopen',
    Block: 'block',
    Unblock: 'unblock',
    Archive: 'archive',
    Unarchive: 'unarchive',
    AuctionReport: 'auction_report',
    AuctionOfferToRunnerUp: 'auction_offer_to_runner_up',
    AuctionAcceptOffer: 'auction_accept_offer',
    MarkAsShipped: 'mark_as_shipped',
    MarkAsReceived: 'mark_as_received',
    ReportMessage: 'report_message',
    ShippingRequestAddress: 'shipping_request_address',
    BuyerReview: 'buyer_review',
    SellerReview: 'seller_review'
} as const;
export type AvailableActionCodesEnum = typeof AvailableActionCodesEnum[keyof typeof AvailableActionCodesEnum];


export function AvailableActionCodesEnumFromJSON(json: any): AvailableActionCodesEnum {
    return AvailableActionCodesEnumFromJSONTyped(json, false);
}

export function AvailableActionCodesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableActionCodesEnum {
    return json as AvailableActionCodesEnum;
}

export function AvailableActionCodesEnumToJSON(value?: AvailableActionCodesEnum | null): any {
    return value as any;
}

