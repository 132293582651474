import { Dispatch, SetStateAction, useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { AddressSide, StandarizedAddressSide } from 'pages/Ship/Generic/enum';
import { AddressT, RecipientHubDetailsT } from 'pages/Ship/Generic/helpers/types';
import usePreventShipping from 'pages/Ship/Generic/hooks/usePreventShipping';
import useValidateAddress from 'pages/Ship/Generic/hooks/useValidateAddress';
import {
  isAddressFilled,
  prepareHubAddressAndPerson,
  preparePerson,
} from 'pages/Ship/Shipping/Add/helpers/formHelpers';
import {
  AddressType,
  LabelFormTypes,
  TemporaryAddressT,
} from 'pages/Ship/Shipping/Add/helpers/types';
import { CreateAddressCallbackProps } from 'pages/Ship/Shipping/Add/hooks/types';
import { useShipping } from 'api/generated/useShipping';
import { useShippingOrders } from 'api/generated/useShippingOrders';
import { PickupTypeEnum, ServiceTypeEnum, ShipmentCreationRequest } from 'api/sdk';
import { useToaster } from 'hooks';
import { OpenState } from 'hooks/utils/useOpenState';
import { DATEINPUT_FORMAT } from 'services/constants';
import { isShippingCartPath } from 'services/urls';
import { WEBSOCKETS_MESSAGE_TYPES } from '../../../../../../../src/js/user-messages-web-sockets';

interface UseShipmentActionsProps {
  openState: OpenState;
  recipientAddress: AddressT;
  temporaryAddress: TemporaryAddressT;
  recipientAddressCallback: (obj: CreateAddressCallbackProps) => void;
  shipperAddressCallback: (obj: CreateAddressCallbackProps) => void;
  temporaryAddressLineCallback: (
    address: AddressT,
    side: AddressSide | StandarizedAddressSide
  ) => void;
  recipientHubAddress: RecipientHubDetailsT | undefined;
  setLabelCreationData: Dispatch<SetStateAction<ShipmentCreationRequest | undefined>>; // add undefined
  labelCreationData: ShipmentCreationRequest | undefined; // add undefined
}

const useShipmentActions = ({
  openState,
  recipientAddress,
  recipientHubAddress,
  recipientAddressCallback,
  shipperAddressCallback,
  temporaryAddressLineCallback,
  setLabelCreationData,
}: UseShipmentActionsProps) => {
  const navigate = useNavigate();

  const { showWarning, showError, showSuccess } = useToaster();
  const { createCreateShipment, error } = useShipping();
  const { createOrdersAddToCart } = useShippingOrders();
  const { validateAddress } = useValidateAddress();
  const { checkIfShippingAllowed } = usePreventShipping();

  // Function that submit address from recipient modal
  // Only recipient, because shipper is non-modal
  const handleValidateSideAddress = async ({
    values,
    side,
  }: {
    values: AddressType;
    side: AddressSide;
  }) => {
    const obj = values[side];
    if (!obj) return;
    const label = side === AddressSide.RECIPIENT ? 'Recipient address' : 'Shipper address';
    const hasAddress = await validateAddress(obj, label);
    if (hasAddress) {
      const standarizedAddress = {
        ...obj,
        city: hasAddress.city,
        addressLine1: hasAddress.streetLines[0],
        addressLine2: hasAddress.streetLines[1] || '',
        postalCode: hasAddress.postalCode,
        state: hasAddress.stateOrProvinceCode,
      };
      const tempSide =
        side === AddressSide.RECIPIENT
          ? StandarizedAddressSide.RECIPIENT_STD
          : StandarizedAddressSide.SHIPPER_STD;
      const keySide = side === AddressSide.RECIPIENT ? AddressSide.RECIPIENT : AddressSide.SHIPPER;
      temporaryAddressLineCallback(standarizedAddress, tempSide);

      if (side === AddressSide.RECIPIENT) {
        return recipientAddressCallback({
          address: obj,
          hasValidSuite: hasAddress.isValidSuiteNumber,
          key: keySide,
        });
      }

      if (side === AddressSide.SHIPPER) {
        return shipperAddressCallback({
          address: obj,
          hasValidSuite: hasAddress.isValidSuiteNumber,
          key: keySide,
        });
      }
    }
  };

  // Function that submits Create Label (Step 1 - Form)
  const handleSubmitLabelDetailsForm = async (values: LabelFormTypes) => {
    const isFilledRecipientAddress = isAddressFilled(recipientAddress);
    const isFilledShipperAddress = isAddressFilled(values.shipper);
    if (!checkIfShippingAllowed()) return;

    if (!isFilledRecipientAddress && !recipientHubAddress) {
      showWarning('Please fill shipping address or select a drop off location');
      return;
    }

    if (!isFilledShipperAddress) {
      showWarning('Please fill shipper address');
      return;
    }

    const data: ShipmentCreationRequest = {
      shipper: preparePerson(values.shipper),
      recipient: preparePerson(recipientAddress),
      pickupType: PickupTypeEnum.DropoffAtFedexLocation,
      serviceType: ServiceTypeEnum.PriorityOvernight,
      packageLineItem: {
        weight: Number(values.weight),
        length: Number(values.length),
        width: Number(values.width),
        height: Number(values.height),
      },
      recipientHubId: undefined,
      shipDateStamp: values.shipDateStamp.toISOString(),
      isInsured: values.insured,
      labelFileType: values.labelFileType,
      printerType: values.labelPrinterType,
    };

    // if recipientHubAddress is set
    // we are using the hub address
    // so the city, street etc is the hub address
    // and the recipient is the person who is receiving the package
    if (recipientHubAddress) {
      data.recipientHubId = String(recipientHubAddress.locationId);
      data.recipient = prepareHubAddressAndPerson(recipientHubAddress);
    }

    setLabelCreationData(data);

    await handleValidateSideAddress({
      values: { shipper: values.shipper },
      side: AddressSide.SHIPPER,
    });
  };

  // Function that creates label in BE
  const handleCreateLabel = async (data: ShipmentCreationRequest) => {
    if (!data) return;

    const dataWithCorrectDate = {
      ...data,
      shipDateStamp: dayjs(data.shipDateStamp).format(DATEINPUT_FORMAT),
    };

    const response = await createCreateShipment({ request: dataWithCorrectDate });
    if (response?.id) {
      await createOrdersAddToCart({ request: { shipmentId: response.id } });
      openState.close();
      showSuccess(`Shipment created with tracking number: ${response.trackingNumber}`);

      if (isShippingCartPath(window.location.pathname)) {
        document.dispatchEvent(new CustomEvent(WEBSOCKETS_MESSAGE_TYPES.REFRESH_SHIPPING_CART));
      } else {
        navigate('/ship/shipping/cart/');
      }
    } else {
      showWarning('Failed to create shipment, please try again');
    }
  };

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  return {
    handleValidateSideAddress,
    handleSubmitLabelDetailsForm,
    handleCreateLabel,
  };
};

export default useShipmentActions;
