import * as Yup from 'yup';
import { EMAIL_FORMAT_REGEX } from 'services/email';
import { US_PHONE_NUMBER_DIGIT_REGEX } from 'services/phoneFormat';
import { AddressFields } from '../../enum';

export const addressOnlyValidationSchema = Yup.object({
  [AddressFields.ADDRESS_LINE_1]: Yup.string()
    .required('Address Line 1 is required')
    .min(3, 'Address Line 1 must be at least 3 characters'),
  [AddressFields.ADDRESS_LINE_2]: Yup.string(),
  [AddressFields.CITY]: Yup.string()
    .required('City is required')
    .min(3, 'City must be at least 3 characters'),
  [AddressFields.STATE]: Yup.string().required('State is required'),
  [AddressFields.POSTAL_CODE]: Yup.string()
    .required('Postal Code is required')
    .matches(/^\d{5}(-\d{4})?$/, 'Postal Code must be 5 digits or in the format 12345-1234'),
});

export const personValidationSchema = Yup.object({
  [AddressFields.FIRST_NAME]: Yup.string().when(AddressFields.COMPANY, {
    is: (company: string) => !company,
    then: (schema) => schema.required('First Name is required'),
    otherwise: (schema) => schema,
  }),
  [AddressFields.LAST_NAME]: Yup.string().when(AddressFields.COMPANY, {
    is: (company: string) => !company,
    then: (schema) => schema.required('Last Name is required'),
    otherwise: (schema) => schema,
  }),
  [AddressFields.COMPANY]: Yup.string(),
  [AddressFields.PHONE]: Yup.string()
    .required('Phone is required')
    .matches(
      US_PHONE_NUMBER_DIGIT_REGEX,
      'Phone must valid 10 digits US phone number without any special characters, e.g. 1234567890'
    ),
  [AddressFields.EMAIL]: Yup.string()
    .email('Invalid email')
    .required('Email is required')
    // FIXME: This is a temporary fix for the insufficient email validation
    // carried out by Yup library that allows email incompatible with our BE.
    .matches(EMAIL_FORMAT_REGEX, 'Invalid email format'),
});

// Validation Schema
export const addressValidationSchema = Yup.object({
  ...personValidationSchema.fields,
  ...addressOnlyValidationSchema.fields,
});
