import { isPlantCategory } from 'hooks/utils/useIsPlant';

export const enableBuyNowText = "Enable 'Buy Now'";
export const disableBuyNowText = "Disable 'Buy Now'";
export const gridSizeChangeWarning =
  'Warning: Editing the group/rack size will overwrite existing data in this row/column. Any current data will be permanently lost. Would you like to proceed with these changes?';
export const shippingServiceBetaDiscountLabel = 'Shipping Beta - All Paid Members*';

export const shippingUnavailableMessage = {
  title: 'Shipping Unavailable',
  description:
    "We're sorry, but shipping feature is only available in the United States at this time.",
};

export const RGITurnAroundTexts = {
  singleMorph: '1-2 business days',
  multiMorph: '3-5 weeks',
  miniMorph: '1-2 business days',
  sexDetermination: '3-5 business days',
};

export const navigationMenuText = {
  dashboard: 'Dashboard',
  myAnimals: 'My Animals',
  husbandry: 'Husbandry',
  breeding: 'Breeding',
  offspring: 'Offspring',
  groupsRacks: 'Groups / Racks',
  activityLog: 'Activity Log',
  orders: 'Orders',
  myStore: 'My Store',
  messages: 'Messages',
  waitlists: 'Waitlists',
  savedSearches: 'Saved Searches',
  myBids: 'My Bids',
  likedAnimals: 'Liked Animals',
  savedAnimals: 'Saved Animals',
  following: 'Following',
  userGroups: 'User Groups',
  accountSettings: 'Account Settings',
  general: 'General',
  orgProfile: 'Org Profile',
  sellerProfile: 'Seller Profile',
  startSelling: 'Start Selling',
  userProfile: 'User Profile',
  labelSettings: 'Label Settings',
  notifications: 'Notifications',
  subscriptions: 'Subscriptions',
  sellerRatings: 'Seller Ratings',
  buyerRatings: 'Buyer Ratings',
  shipping: 'Shipping',
};

export const shippingMenuText = {
  rateCalculator: 'Rate Calculator',
  schedulePickup: 'Schedule Pickup',
  savedAddresses: 'Saved Addresses',
  insurance: 'Insurance',
  terms: 'Terms',
  getHelp: 'Get Help',
};

export const listingItemText = (categoryId: number, plural = false) => {
  const item = isPlantCategory(categoryId, true) ? 'plant' : 'animal';
  if (plural) {
    return `${item}s`;
  }
  return item;
};

export const representativePhotoText = (categoryId: number, plural = false) => {
  const itemText = listingItemText(categoryId);
  const baseText = 'Example photo.';
  return plural
    ? `${baseText} One or more of the available ${itemText} are not pictured.`
    : ` Exact ${itemText} is not shown.`;
};

export const listingNotFoundText = (categoryId: number) => {
  return `There are no ${listingItemText(categoryId, true)} that match your criteria.`;
};

export const keywordFilterText = (categoryId: number) => {
  return `Search by ${listingItemText(categoryId)} title or ID`;
};

export const categoryPhrases = {
  functions: {
    listingItemText,
    keywordFilterText,
    listingNotFoundText,
    representativePhotoText,
  },
};

export enum ShippingLabelPrinterPreference {
  THERMAL_PNG = 'Thermal .PNG',
  LASER_INKJET_PDF = 'Laser/Inkjet Printer .PDF',
}
