import { initialPerson } from 'pages/Ship/Generic/Form/helpers/initialValues';
import { PackageFields, LabelGeneralFields } from 'pages/Ship/Shipping/Add/helpers/enum';
import { LabelFormErrors, LabelFormTypes } from 'pages/Ship/Shipping/Add/helpers/types';
import { LabelFileTypeEnum, PrinterTypeEnum, ServiceTypeEnum } from 'api/sdk';

// Initial Ship Date must be a weekday
// This function checks if today is weekend and if so, it sets the date to the next Monday
export const initialShipDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();

  // If today is Saturday (6), add 2 days to get to Monday
  // If today is Sunday (0), add 1 day to get to Monday
  if (dayOfWeek === 0) {
    today.setDate(today.getDate() + 1);
  } else if (dayOfWeek === 6) {
    today.setDate(today.getDate() + 2);
  }
  return today;
};

export const initialValues: LabelFormTypes = {
  [LabelGeneralFields.SHIPPER]: { ...initialPerson },
  [LabelGeneralFields.RECIPIENT]: { ...initialPerson },
  [LabelGeneralFields.PICKUP_TYPE]: undefined,
  [LabelGeneralFields.SERVICE_TYPE]: ServiceTypeEnum.PriorityOvernight,
  [PackageFields.WEIGHT]: '',
  [PackageFields.LENGTH]: '',
  [PackageFields.WIDTH]: '',
  [PackageFields.HEIGHT]: '',
  [LabelGeneralFields.RECIPIENT_HUB_ID]: undefined,
  [LabelGeneralFields.SHIP_DATE_STAMP]: initialShipDate(),
  [LabelGeneralFields.INSURED]: true,
  [LabelGeneralFields.LABEL_FILE_TYPE]: LabelFileTypeEnum.Pdf,
  // Info: Hidden field, and this field is used to determine the printer type based on the file type selected.
  [LabelGeneralFields.LABEL_PRINTER_TYPE]: PrinterTypeEnum.Laser,
};

export const initialErrors: LabelFormErrors = {
  [LabelGeneralFields.SHIPPER]: false,
  [LabelGeneralFields.RECIPIENT]: false,
  [LabelGeneralFields.PICKUP_TYPE]: false,
  [LabelGeneralFields.SERVICE_TYPE]: false,
  [LabelGeneralFields.PACKAGE_LINE_ITEM]: false,
};
