import dayjs from 'dayjs';
import { DATE_FORMAT } from 'services/constants/datetime';

export const dateToString = (date: Date): string => {
  return dayjs(date).format(DATE_FORMAT);
};

export const convertTimeTo12HourFormat = (time?: string | null): string | null => {
  if (!time) return null;
  const [hours, minutes, seconds] = time.split(':');
  const suffix: string = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
  const hours12: number = parseInt(hours, 10) % 12 || 12;
  return `${hours12}:${minutes} ${suffix}`;
};
