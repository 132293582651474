/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Temperature,
    TemperatureFromJSON,
    TemperatureFromJSONTyped,
    TemperatureToJSON,
} from './Temperature';

/**
 * 
 * @export
 * @interface DailyWeatherAtPostcode
 */
export interface DailyWeatherAtPostcode {
    /**
     * 
     * @type {Temperature}
     * @memberof DailyWeatherAtPostcode
     */
    temperatureMax: Temperature;
    /**
     * 
     * @type {Temperature}
     * @memberof DailyWeatherAtPostcode
     */
    temperatureMin: Temperature;
    /**
     * 
     * @type {Date}
     * @memberof DailyWeatherAtPostcode
     */
    date: Date;
}

export function DailyWeatherAtPostcodeFromJSON(json: any): DailyWeatherAtPostcode {
    return DailyWeatherAtPostcodeFromJSONTyped(json, false);
}

export function DailyWeatherAtPostcodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyWeatherAtPostcode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'temperatureMax': TemperatureFromJSON(json['temperature_max']),
        'temperatureMin': TemperatureFromJSON(json['temperature_min']),
        'date': (new Date(json['date'])),
    };
}

export function DailyWeatherAtPostcodeToJSON(value?: DailyWeatherAtPostcode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'temperature_max': TemperatureToJSON(value.temperatureMax),
        'temperature_min': TemperatureToJSON(value.temperatureMin),
        'date': (value.date.toISOString().substr(0,10)),
    };
}

