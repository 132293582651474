import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { AddressSide, ShipModalState } from 'pages/Ship/Generic/enum';
import { DisplayHubAddress } from 'pages/Ship/Generic/Form/DisplayFields/DisplayHubAddress/DisplayHubAddress';
import { DisplayResidentialOrCommercialAddress } from 'pages/Ship/Generic/Form/DisplayFields/DisplayResidentialOrCommercialAddress/DisplayResidentialOrCommercialAddress';
import SectionTitle from 'pages/Ship/Generic/Form/SectionTitle';
import { isAddressFilled } from 'pages/Ship/Shipping/Add/helpers/formHelpers';
import { useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import { WhenWrapper } from 'components/experimental/Wrappers';
import styles from './style.module.scss';

const Recipient = () => {
  const { handleModalStep, onEditResidentialAddress, recipientAddress, recipientHubAddress } =
    useShipment();

  const isAddressSelected = !!recipientAddress && isAddressFilled(recipientAddress);
  const isHubSelected = !!recipientHubAddress && recipientHubAddress.locationId;

  return (
    <div>
      <SectionTitle
        title="To"
        sideChild={
          <>
            <FontAwesomeIcon icon={faSearch} style={{ opacity: 0.3 }} />
            <Box sx={{ ml: 1, opacity: 0.3 }}>Saved Addresses</Box>
          </>
        }
      />
      <WhenWrapper condition={isHubSelected || isAddressSelected}>
        <Box mb={2}>
          {isAddressSelected && (
            <DisplayResidentialOrCommercialAddress
              addressData={recipientAddress}
              actionsProps={{
                onEdit: () => onEditResidentialAddress(AddressSide.RECIPIENT),
              }}
            />
          )}
          {isHubSelected && (
            <>
              <DisplayHubAddress
                hubAddress={recipientHubAddress}
                personDetails={recipientHubAddress.recipient}
                actionsProps={{
                  onEdit: () => handleModalStep(ShipModalState.RECIPIENT_HUB_SEARCH),
                }}
              />
            </>
          )}
        </Box>
      </WhenWrapper>

      <div className={styles.outer}>
        <Button
          sx={{ display: isHubSelected ? 'none' : 'flex' }}
          size="large"
          variant="contained"
          onClick={() => handleModalStep(ShipModalState.RECIPIENT_HUB_SEARCH)}
        >
          FedEx Facility (Recommended)
        </Button>

        <Button size="large" variant="outlined" disabled>
          MorphMarket User
        </Button>

        <Button
          size="large"
          variant="outlined"
          sx={{ display: isAddressSelected ? 'none' : 'flex' }}
          onClick={() => handleModalStep(ShipModalState.RECIPIENT_ADDRESS)}
        >
          Shipping Address
        </Button>
      </div>
    </div>
  );
};

export default Recipient;
