/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactDetails,
    ContactDetailsFromJSON,
    ContactDetailsFromJSONTyped,
    ContactDetailsToJSON,
} from './ContactDetails';
import {
    Distance,
    DistanceFromJSON,
    DistanceFromJSONTyped,
    DistanceToJSON,
} from './Distance';
import {
    HubHours,
    HubHoursFromJSON,
    HubHoursFromJSONTyped,
    HubHoursToJSON,
} from './HubHours';
import {
    ShippingLocation,
    ShippingLocationFromJSON,
    ShippingLocationFromJSONTyped,
    ShippingLocationToJSON,
} from './ShippingLocation';

/**
 * 
 * @export
 * @interface StandardizedLocationDetail
 */
export interface StandardizedLocationDetail {
    /**
     * 
     * @type {string}
     * @memberof StandardizedLocationDetail
     */
    locationId: string;
    /**
     * 
     * @type {Distance}
     * @memberof StandardizedLocationDetail
     */
    distance: Distance;
    /**
     * 
     * @type {ContactDetails}
     * @memberof StandardizedLocationDetail
     */
    contact: ContactDetails;
    /**
     * 
     * @type {ShippingLocation}
     * @memberof StandardizedLocationDetail
     */
    address: ShippingLocation;
    /**
     * 
     * @type {Array<HubHours>}
     * @memberof StandardizedLocationDetail
     */
    hubHours: Array<HubHours> | null;
}

export function StandardizedLocationDetailFromJSON(json: any): StandardizedLocationDetail {
    return StandardizedLocationDetailFromJSONTyped(json, false);
}

export function StandardizedLocationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandardizedLocationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['location_id'],
        'distance': DistanceFromJSON(json['distance']),
        'contact': ContactDetailsFromJSON(json['contact']),
        'address': ShippingLocationFromJSON(json['address']),
        'hubHours': (json['hub_hours'] === null ? null : (json['hub_hours'] as Array<any>).map(HubHoursFromJSON)),
    };
}

export function StandardizedLocationDetailToJSON(value?: StandardizedLocationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location_id': value.locationId,
        'distance': DistanceToJSON(value.distance),
        'contact': ContactDetailsToJSON(value.contact),
        'address': ShippingLocationToJSON(value.address),
        'hub_hours': (value.hubHours === null ? null : (value.hubHours as Array<any>).map(HubHoursToJSON)),
    };
}

