/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `fedex` - FedEx
 * @export
 */
export const ShippingProviderEnum = {
    Fedex: 'fedex'
} as const;
export type ShippingProviderEnum = typeof ShippingProviderEnum[keyof typeof ShippingProviderEnum];


export function ShippingProviderEnumFromJSON(json: any): ShippingProviderEnum {
    return ShippingProviderEnumFromJSONTyped(json, false);
}

export function ShippingProviderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingProviderEnum {
    return json as ShippingProviderEnum;
}

export function ShippingProviderEnumToJSON(value?: ShippingProviderEnum | null): any {
    return value as any;
}

