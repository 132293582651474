/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LabelFileTypeEnum,
    LabelFileTypeEnumFromJSON,
    LabelFileTypeEnumFromJSONTyped,
    LabelFileTypeEnumToJSON,
} from './LabelFileTypeEnum';
import {
    PackageLineItemRequest,
    PackageLineItemRequestFromJSON,
    PackageLineItemRequestFromJSONTyped,
    PackageLineItemRequestToJSON,
} from './PackageLineItemRequest';
import {
    PersonRequest,
    PersonRequestFromJSON,
    PersonRequestFromJSONTyped,
    PersonRequestToJSON,
} from './PersonRequest';
import {
    PickupTypeEnum,
    PickupTypeEnumFromJSON,
    PickupTypeEnumFromJSONTyped,
    PickupTypeEnumToJSON,
} from './PickupTypeEnum';
import {
    PrinterTypeEnum,
    PrinterTypeEnumFromJSON,
    PrinterTypeEnumFromJSONTyped,
    PrinterTypeEnumToJSON,
} from './PrinterTypeEnum';
import {
    ServiceTypeEnum,
    ServiceTypeEnumFromJSON,
    ServiceTypeEnumFromJSONTyped,
    ServiceTypeEnumToJSON,
} from './ServiceTypeEnum';

/**
 * 
 * @export
 * @interface ShipmentCreationRequest
 */
export interface ShipmentCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ShipmentCreationRequest
     */
    provider?: string;
    /**
     * 
     * @type {PersonRequest}
     * @memberof ShipmentCreationRequest
     */
    shipper: PersonRequest;
    /**
     * 
     * @type {PersonRequest}
     * @memberof ShipmentCreationRequest
     */
    recipient: PersonRequest;
    /**
     * 
     * @type {string}
     * @memberof ShipmentCreationRequest
     */
    shipDateStamp: string;
    /**
     * 
     * @type {ServiceTypeEnum}
     * @memberof ShipmentCreationRequest
     */
    serviceType: ServiceTypeEnum;
    /**
     * 
     * @type {PackageLineItemRequest}
     * @memberof ShipmentCreationRequest
     */
    packageLineItem: PackageLineItemRequest;
    /**
     * 
     * @type {PickupTypeEnum}
     * @memberof ShipmentCreationRequest
     */
    pickupType: PickupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShipmentCreationRequest
     */
    recipientHubId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentCreationRequest
     */
    isInsured?: boolean;
    /**
     * 
     * @type {LabelFileTypeEnum}
     * @memberof ShipmentCreationRequest
     */
    labelFileType: LabelFileTypeEnum;
    /**
     * 
     * @type {PrinterTypeEnum}
     * @memberof ShipmentCreationRequest
     */
    printerType: PrinterTypeEnum;
}

export function ShipmentCreationRequestFromJSON(json: any): ShipmentCreationRequest {
    return ShipmentCreationRequestFromJSONTyped(json, false);
}

export function ShipmentCreationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentCreationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'shipper': PersonRequestFromJSON(json['shipper']),
        'recipient': PersonRequestFromJSON(json['recipient']),
        'shipDateStamp': json['ship_date_stamp'],
        'serviceType': ServiceTypeEnumFromJSON(json['service_type']),
        'packageLineItem': PackageLineItemRequestFromJSON(json['package_line_item']),
        'pickupType': PickupTypeEnumFromJSON(json['pickup_type']),
        'recipientHubId': !exists(json, 'recipient_hub_id') ? undefined : json['recipient_hub_id'],
        'isInsured': !exists(json, 'is_insured') ? undefined : json['is_insured'],
        'labelFileType': LabelFileTypeEnumFromJSON(json['label_file_type']),
        'printerType': PrinterTypeEnumFromJSON(json['printer_type']),
    };
}

export function ShipmentCreationRequestToJSON(value?: ShipmentCreationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'shipper': PersonRequestToJSON(value.shipper),
        'recipient': PersonRequestToJSON(value.recipient),
        'ship_date_stamp': value.shipDateStamp,
        'service_type': ServiceTypeEnumToJSON(value.serviceType),
        'package_line_item': PackageLineItemRequestToJSON(value.packageLineItem),
        'pickup_type': PickupTypeEnumToJSON(value.pickupType),
        'recipient_hub_id': value.recipientHubId,
        'is_insured': value.isInsured,
        'label_file_type': LabelFileTypeEnumToJSON(value.labelFileType),
        'printer_type': PrinterTypeEnumToJSON(value.printerType),
    };
}

