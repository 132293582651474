import React from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import styles from 'pages/Ship/Generic/Form/DisplayFields/styles.module.scss';
import { HubAddressT, PersonContactT } from 'pages/Ship/Generic/helpers/types';
import { HubName } from 'pages/Ship/UserShippingAddress/enum';
import { WhenWrapper } from 'components/experimental/Wrappers';
import { formatPhoneNumber } from 'services/phoneFormat';
import { Actions, ActionsProps, MakeDefaultAction } from '../components/Actions';
import { formatDistance } from './helper';

type RecipientDetailsProps = {
  personDetails?: PersonContactT;
};

export const RecipientDetails: React.FC<RecipientDetailsProps> = ({ personDetails }) => {
  if (!personDetails) return null;

  const { firstName, lastName, email, phone, company } = personDetails;
  const boldTextStyles = classNames(styles.text, styles.bold);

  return (
    <Box className={styles.personWrapper}>
      <p className={boldTextStyles}>{`${firstName} ${lastName}`}</p>
      <p className={boldTextStyles}>{company}</p>
      <p className={styles.text}>{email}</p>
      <p className={styles.text}>{formatPhoneNumber(phone)}</p>
    </Box>
  );
};

type HubAddressDetailsProps = {
  isDefault?: boolean;
  hubAddress: HubAddressT;
  actionsProps?: ActionsProps;
  personDetails?: PersonContactT;
};

export const HubAddressDetails: React.FC<HubAddressDetailsProps> = ({
  hubAddress,
  isDefault,
  actionsProps,
  personDetails,
}) => {
  const { providerName, address, distance, locationId } = hubAddress;
  const { addressLine1, addressLine2, city, state, postalCode } = address;
  const { onEdit, onDelete, onMakeDefault } = actionsProps || {};

  const providerNameJSX = providerName && (
    <p className={classNames(styles.text, styles.bold)}>{HubName[providerName]}</p>
  );

  return (
    <Box className={styles.addressWrapper}>
      <div className={styles.between}>
        {providerNameJSX}
        <Actions onEdit={onEdit} onDelete={onDelete} />
      </div>
      <p className={styles.text}>{addressLine1}</p>
      <p className={styles.text}>
        <WhenWrapper condition={addressLine2}>
          <span>{addressLine2}, </span>
        </WhenWrapper>
        ID {locationId}
      </p>
      <p className={styles.text}>{`${city}, ${state} ${postalCode}`}</p>
      <p className={styles.text}>{formatDistance(distance)}</p>
      <RecipientDetails personDetails={personDetails as PersonContactT} />
      <MakeDefaultAction isDefault={isDefault} onMakeDefault={onMakeDefault} />
    </Box>
  );
};

export const DisplayHubAddress: React.FC<HubAddressDetailsProps> = ({
  isDefault,
  hubAddress,
  personDetails,
  actionsProps,
}) => {
  return (
    <Box className={styles.outer}>
      <HubAddressDetails
        isDefault={isDefault}
        hubAddress={hubAddress}
        actionsProps={actionsProps}
        personDetails={personDetails}
      />
    </Box>
  );
};
