import * as Yup from 'yup';
import { AddressFields } from 'pages/Ship/Generic/enum';
import { AddressOnlyT } from 'pages/Ship/Generic/helpers/types';

import { useHubAddress } from './useHubAddress';

export type UseHubSearchProps = {
  nextStep: () => void;
};

/*
CAUTION: Modify this provider with care, because it is used in different context & components.
Importantly, don't mix up the logic with other providers and try to keep it as a single responsibility provider.
*/
export const useHubSearch = (hubSearchProps?: UseHubSearchProps) => {
  const { nextStep } = hubSearchProps || {};
  const { searchFedex } = useHubAddress();

  const formikBag = {
    onSubmit: async (values: { hubAddress: Pick<AddressOnlyT, 'postalCode'> }) => {
      try {
        await searchFedex(values.hubAddress);
        nextStep?.();
      } catch (error) {
        console.error('Error searching for hub:', error);
      }
    },
    enableReinitialize: true,
    initialValues: {
      hubAddress: {
        [AddressFields.POSTAL_CODE]: '',
      },
    },
    validationSchema: Yup.object({
      hubAddress: Yup.object({
        [AddressFields.POSTAL_CODE]: Yup.string()
          .required('Postal code is required')
          .min(5, 'Postal code is too short')
          .matches(/^\d{5}$/, 'Postal code must be 5 digits'),
      }),
    }),
  };

  return {
    formikBag,
  };
};
