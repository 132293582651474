import React from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box/Box';
import { Stack } from '@mui/system';
import { Field, Form, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { AddressFields, ShipModalState } from 'pages/Ship/Generic/enum';
import { useAddressFrom } from 'pages/Ship/Generic/Form/hooks/useAddressForm';
import { useUserShippingAddress } from 'pages/Ship/UserShippingAddress/hooks/useUserShippingAddress';
import { DrawerDash } from 'components/DrawerDash/DrawerDash';
import { Drawer } from 'components/experimental/Drawer/Drawer';
import { WhenWrapper } from 'components/experimental/Wrappers';
import SidebarWrapper from 'components/Sidebar/SidebarWrapper';
import Spinner from 'components/Spinner/Spinner';
import styles from '../../EditHubAddress.module.scss';

interface SearchHubsProps {
  prev?: () => void;
  formStyles?: React.CSSProperties;
}

export const SearchHubs: React.FC<SearchHubsProps> = ({ prev, formStyles }) => {
  const { isSubmitting } = useFormikContext();
  const { addPrefix } = useAddressFrom({ namePrefix: 'hubAddress' });

  return (
    <Form style={formStyles}>
      <Box mt={2}>
        <WhenWrapper condition={isSubmitting}>
          <Box className={styles.spinner}>
            <Spinner />
          </Box>
        </WhenWrapper>
        <Field
          component={TextField}
          label="Postal Code"
          placeholder="Postal Code"
          sx={{ width: '100%' }}
          name={addPrefix(AddressFields.POSTAL_CODE)}
        />
        <Stack mt={2} gap={2} flexDirection="row">
          <WhenWrapper condition={prev}>
            <Button size="large" variant="outlined" fullWidth onClick={prev}>
              Back
            </Button>
          </WhenWrapper>
          <Button size="large" variant="contained" type="submit" fullWidth disabled={isSubmitting}>
            Search Hubs
          </Button>
        </Stack>
      </Box>
    </Form>
  );
};

export const SearchHubsDrawer: React.FC<SearchHubsProps> = (props) => {
  const { modalStep, actions } = useUserShippingAddress();

  return (
    <Drawer
      isExpanded
      anchor="bottom"
      isOpen={modalStep == ShipModalState.RECIPIENT_HUB}
      actions={{ open: () => null, close: () => actions.handleModalStep(ShipModalState.START) }}
    >
      <DrawerDash />
      <SidebarWrapper title="Search Hubs" isDrawer isDrawerAutoHeight={true}>
        <Stack flexGrow={1} padding={2}>
          <SearchHubs {...props} />
        </Stack>
      </SidebarWrapper>
    </Drawer>
  );
};

export const SearchHubAddress: React.FC<{ isMobile: boolean; prev?: () => void }> = ({
  prev,
  isMobile,
}) => {
  return isMobile ? <SearchHubsDrawer prev={prev} /> : <SearchHubs prev={prev} />;
};

export default SearchHubs;
