import { TemporaryAddressT } from 'pages/Ship/Shipping/Add/helpers/types';
import { AddressFields, AddressSide, StandarizedAddressSide } from '../../enum';
import { AddressOnlyT, AddressT } from '../../helpers/types';

export const initialAddress: AddressOnlyT = {
  [AddressFields.ADDRESS_LINE_1]: '',
  [AddressFields.ADDRESS_LINE_2]: '',
  [AddressFields.CITY]: '',
  [AddressFields.STATE]: '',
  [AddressFields.POSTAL_CODE]: '',
  [AddressFields.IS_RESIDENTIAL]: true,
};

export const initialPerson: AddressT = {
  [AddressFields.FIRST_NAME]: '',
  [AddressFields.LAST_NAME]: '',
  [AddressFields.COMPANY]: '',
  [AddressFields.PHONE]: '',
  [AddressFields.EMAIL]: '',
  ...initialAddress,
};

export const initialTemporaryAddress: TemporaryAddressT = {
  [AddressSide.RECIPIENT]: undefined,
  [AddressSide.SHIPPER]: undefined,
  [StandarizedAddressSide.RECIPIENT_STD]: undefined,
  [StandarizedAddressSide.SHIPPER_STD]: undefined,
};
