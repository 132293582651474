export enum PackageFields {
  WEIGHT = 'weight',
  LENGTH = 'length',
  WIDTH = 'width',
  HEIGHT = 'height',
}

export enum LabelGeneralFields {
  SHIPPER = 'shipper',
  RECIPIENT = 'recipient',
  PICKUP_TYPE = 'pickupType',
  SERVICE_TYPE = 'serviceType',
  PACKAGE_LINE_ITEM = 'packageLineItem',
  RECIPIENT_HUB_ID = 'recipientHubId',
  SHIP_DATE_STAMP = 'shipDateStamp',
  INSURED = 'insured',
  LABEL_FILE_TYPE = 'labelFileType',
  // Info: Hidden field, and this field is used to determine the printer type based on the file type selected.
  LABEL_PRINTER_TYPE = 'labelPrinterType',
}
