import { useEffect, useState } from 'react';
import { AddressSide, ShipModalState, StandarizedAddressSide } from 'pages/Ship/Generic/enum';
import {
  initialPerson,
  initialTemporaryAddress,
} from 'pages/Ship/Generic/Form/helpers/initialValues';
import { useAddressEdit } from 'pages/Ship/Generic/Form/hooks/useAddressEdit';
import { useModal } from 'pages/Ship/Generic/Form/hooks/useModal';
import { AddressT, HubAddressT, RecipientHubDetailsT } from 'pages/Ship/Generic/helpers/types';
import { useDefaultUserAddress } from 'pages/Ship/Generic/hooks/useDefaultUserAddress';
import { ServicePurchaseData } from 'pages/Ship/RateCalculator/components/ServiceCard/BuyService';
import { preparePerson } from 'pages/Ship/Shipping/Add/helpers/formHelpers';
import { TemporaryAddressT } from 'pages/Ship/Shipping/Add/helpers/types';
import {
  AddressCallbackProps,
  CreateAddressCallbackProps,
} from 'pages/Ship/Shipping/Add/hooks/types';
import useShipmentActions from 'pages/Ship/Shipping/Add/hooks/useShipmentActions';
import { convertUserAddressToAddressT } from 'pages/Ship/UserShippingAddress/helpers/transformShippingAddressData';
import { generateSingletonHook } from 'context/SingletonContext';
import { ShipmentCreationRequest } from 'api/sdk';

const useDecoratedHook = () => {
  const { getDefaultAddress } = useDefaultUserAddress();
  const [defaultShipperAddress, setDefaultShipperAddress] = useState<AddressT>();
  const [validateFor, setValidateFor] = useState<AddressSide | undefined>(undefined);
  const { openState, openNested, handleModalStep, modalStep, setModalStep } = useModal();
  const { onEditHubAddress, onEditResidentialAddress } = useAddressEdit({ handleModalStep });
  const [shipperAddress, setShipperAddress] = useState<AddressT>(initialPerson);
  const [recipientAddress, setRecipientAddress] = useState<AddressT>(initialPerson);
  const [temporaryAddress, setTemporaryAddress] =
    useState<TemporaryAddressT>(initialTemporaryAddress);
  const [recipientHubAddress, setRecipientHubAddress] = useState<RecipientHubDetailsT>();
  const [selectedHub, setSelectedHub] = useState<HubAddressT>();
  // We use this serviceInfo to pass the service type to the label form from the rate calculator.
  const [servicePurchaseData, setServicePurchaseData] = useState<ServicePurchaseData | undefined>();
  const [labelCreationData, setLabelCreationData] = useState<ShipmentCreationRequest>();
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [dropOffLocationPostalCode, setDropOffLocationPostalCode] = useState<string>();

  const addressCallback = ({
    address,
    hasValidSuite,
    addressIsValidated,
    side,
    setAddress,
    key,
  }: AddressCallbackProps) => {
    // Address is validated and it's valid
    if (addressIsValidated) {
      setAddress(address);
      setTemporaryAddress(initialTemporaryAddress);

      // Shipper address is validated on 'continue' button click
      // Recipient address is validated right after user fills data
      if (side === AddressSide.SHIPPER && address) {
        setLabelCreationData((prev) => {
          if (prev) {
            return {
              ...prev,
              shipper: preparePerson(address),
            };
          }
          return prev;
        });
        setShowConfirmationPage(true);
      }
      // Reset hub address if it's a recipient address
      if (side === AddressSide.RECIPIENT && address) {
        setRecipientHubAddress(undefined);
        setSelectedHub(undefined);
      }
      openNested.close();
      setModalStep(undefined);
    } else {
      setValidateFor(side);
      handleModalStep(
        hasValidSuite ? ShipModalState.VALIDATE_ADDRESS : ShipModalState.INVALID_ADDRESS
      );

      setTemporaryAddress((prev) => ({
        ...prev,
        [key]: address,
      }));
    }

    return address;
  };

  const setDefaultShipperAddressIfAvailable = async () => {
    const defaultAddress = await getDefaultAddress();
    if (!defaultAddress) return null;
    setDefaultShipperAddress(convertUserAddressToAddressT(defaultAddress));
  };

  const handleOpenLabelCreationModal = (servicePurchaseData?: ServicePurchaseData) => {
    openState.open();
    if (servicePurchaseData) {
      setServicePurchaseData(servicePurchaseData);
      const { recipientAddress, recipientHubAddress } = servicePurchaseData;
      if (recipientAddress) {
        recipientAddressCallback({
          address: recipientAddress,
          hasValidSuite: true,
          addressIsValidated: true,
          key: AddressSide.RECIPIENT,
        });
      } else if (recipientHubAddress) {
        setSelectedHub(recipientHubAddress);
        setRecipientHubAddress(recipientHubAddress);
      }
    }
    void setDefaultShipperAddressIfAvailable();
  };

  const createAddressCallback = (side: AddressSide, setAddress: (address: AddressT) => void) => {
    return ({ address, hasValidSuite, addressIsValidated, key }: CreateAddressCallbackProps) =>
      addressCallback({ address, hasValidSuite, addressIsValidated, side, setAddress, key });
  };

  const recipientAddressCallback = createAddressCallback(
    AddressSide.RECIPIENT,
    setRecipientAddress
  );

  const shipperAddressCallback = createAddressCallback(AddressSide.SHIPPER, setShipperAddress);

  const temporaryAddressLineCallback = (
    address: AddressT | undefined,
    type: AddressSide | StandarizedAddressSide
  ) =>
    setTemporaryAddress((prev) => ({
      ...prev,
      [type]: address,
    }));

  const actions = useShipmentActions({
    openState,
    recipientAddress,
    recipientHubAddress,
    temporaryAddress,
    recipientAddressCallback,
    shipperAddressCallback,
    temporaryAddressLineCallback,
    setLabelCreationData,
    labelCreationData,
  });

  const resetForm = () => {
    setModalStep(undefined);
    setRecipientAddress(initialPerson);
    setTemporaryAddress(initialTemporaryAddress);
    setRecipientHubAddress(undefined);
  };

  const backToLabelForm = () => {
    setLabelCreationData(undefined);
    setShowConfirmationPage(false);
  };

  useEffect(() => {
    if (!openState.isOpen) {
      resetForm();
    }
  }, [openState.isOpen]);

  return {
    openState,
    openNested,
    modalStep,
    validateFor,
    shipperAddress,
    defaultShipperAddress,
    selectedHub,
    servicePurchaseData,
    setSelectedHub,
    recipientAddress,
    setRecipientAddress,
    recipientHubAddress,
    setRecipientHubAddress,
    handleModalStep,
    onEditHubAddress,
    onEditResidentialAddress,
    temporaryAddress,
    shipperAddressCallback,
    recipientAddressCallback,
    temporaryAddressLineCallback,
    labelCreationData,
    setLabelCreationData,
    handleOpenLabelCreationModal,
    showConfirmationPage,
    backToLabelForm,
    actions,
    setDropOffLocationPostalCode,
    dropOffLocationPostalCode,
  };
};

const [ShipmentProvider, useShipment] = generateSingletonHook(useDecoratedHook);
export { ShipmentProvider, useShipment };
